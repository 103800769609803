<template>
  <v-container
    style="max-width: 1200px"
    class="justify-center d-flex flex-column fill-width"
    v-if="currentUser"
    >
    <Header
      title="Ofertas"
      >
      <template 
        v-slot:default
        >
        <v-spacer></v-spacer>
        <v-btn
          tile
          depressed
          color="success"
          @click="createPromotion"
          >
          crear
        </v-btn>
      </template>
    </Header>

    <create
      ref="create"
      @reload="reload"
      ></create>

    <v-card>
      <v-card-text>
        <list
          ref="list"
          @firstOrder="refreshCreate"
          ></list>
      </v-card-text>
    </v-card>
  </v-container>
</template>

<script>
import { mapGetters } from 'vuex'

const Header = () => import('@/components/admin/shared/Header')
const List = () => import('@/components/admin/promotions/List')
const Create = () => import('@/components/admin/promotions/Create')

export default {
  components: {
    Header, List, Create
  },

  computed: {
    ...mapGetters(['currentUser']),
  },

  methods: {
    createPromotion () {
      this.$refs.create.modal = true
    },

    reload () {
      if (this.$refs.list) {
        this.$refs.list.fetchPromotions()
      }
    },

    refreshCreate (firstOrder) {
      this.$refs.create.firstOrder = firstOrder
    }
  }
}
</script>
